// build possible age values for visitors to the site
const minAge = 21;
const maxAge = 99;

let ageValues = [];

for (let i = minAge; i <= maxAge; i++) {
  ageValues.push(`${i}`);
}

export const ageRangeValues = [
  // 21-24, 25-34, 35-44, 45-54, 55-64, and 65+
  "21 - 24",
  "25 - 34",
  "35 - 44",
  "45 - 54",
  "55 - 64",
  "65+"
];

export const genderValues = [
  'Female',
  'Male',
  'Non-binary',
];

export const stateValues = [
  // Possible state values
  // 50 states + District of Columbia (DC)
  {
    name: 'Alabama',
    abbr: 'AL',
  },
  {
    name: 'Alaska',
    abbr: 'AK',
  },
  {
    name: 'Arizona',
    abbr: 'AZ',
  },
  {
    name: 'Arizona',
    abbr: 'AR',
  },
  {
    name: 'California',
    abbr: 'CA',
  },
  {
    name: 'Colorado',
    abbr: 'CO',
  },
  {
    name: 'Connecticut',
    abbr: 'CT',
  },
  {
    name: 'Delaware',
    abbr: 'DE',
  },
  {
    name: 'District of Columbia',
    abbr: 'DC',
  },
  {
    name: 'Florida',
    abbr: 'FL',
  },
  {
    name: 'Georgia',
    abbr: 'GA',
  },
  {
    name: 'Hawaii',
    abbr: 'HI',
  },
  {
    name: 'Idaho',
    abbr: 'ID',
  },
  {
    name: 'Illinois',
    abbr: 'IL',
  },
  {
    name: 'Indiana',
    abbr: 'IN',
  },
  {
    name: 'Iowa',
    abbr: 'IA',
  },
  {
    name: 'Kansas',
    abbr: 'KS',
  },
  {
    name: 'Kentucky',
    abbr: 'KY',
  },
  {
    name: 'Louisiana',
    abbr: 'LA',
  },
  {
    name: 'Maine',
    abbr: 'ME',
  },
  {
    name: 'Maryland',
    abbr: 'MD',
  },
  {
    name: 'Massachusetts',
    abbr: 'MA',
  },
  {
    name: 'Michigan',
    abbr: 'MI',
  },
  {
    name: 'Minnesota',
    abbr: 'MN',
  },
  {
    name: 'Mississippi',
    abbr: 'MS',
  },
  {
    name: 'Missouri',
    abbr: 'MO',
  },
  {
    name: 'Montana',
    abbr: 'MT',
  },
  {
    name: 'Nebraska',
    abbr: 'NE',
  },
  {
    name: 'Nevada',
    abbr: 'NV',
  },
  {
    name: 'New Hampshire',
    abbr: 'NH',
  },
  {
    name: 'New Jersey',
    abbr: 'NJ',
  },
  {
    name: 'New Mexico',
    abbr: 'NM',
  },
  {
    name: 'New York',
    abbr: 'NY',
  },
  {
    name: 'North Carolina',
    abbr: 'NC',
  },
  {
    name: 'North Dakota',
    abbr: 'ND',
  },
  {
    name: 'Ohio',
    abbr: 'OH',
  },
  {
    name: 'Oklahoma',
    abbr: 'OK',
  },
  {
    name: 'Oregon',
    abbr: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbr: 'PA',
  },
  {
    name: 'Rhode Island',
    abbr: 'RI',
  },
  {
    name: 'South Carolina',
    abbr: 'SC',
  },
  {
    name: 'South Dakota',
    abbr: 'SD',
  },
  {
    name: 'Tennessee',
    abbr: 'TN',
  },
  {
    name: 'Texas',
    abbr: 'TX',
  },
  {
    name: 'Utah',
    abbr: 'UT',
  },
  {
    name: 'Vermont',
    abbr: 'VT',
  },
  {
    name: 'Virginia',
    abbr: 'VA',
  },
  {
    name: 'Washington',
    abbr: 'WA',
  },
  {
    name: 'West Virginia',
    abbr: 'WV',
  },
  {
    name: 'Wisconsin',
    abbr: 'WI',
  },
  {
    name: 'Wyoming',
    abbr: 'WY',
  },
];

export const storeInfo = [
  {
    id: 'ct001',
    dispensaryTypes: ['medical', 'recreational'],
    name: "West Hartford, CT",
    latlng: {
      lat: 41.756653129357346,
      lng: -72.71516326048189
    },
    address: {
      street: "2 Park Rd.",
      city: "West Hartford",
      state: "CT",
      zip: "06119",
      country: "United States"
    },
    phone: '860.838.8330',
    email: 'westhartford@sweetspotfarms.com',
    googleLink: 'https://maps.app.goo.gl/Lx3f7yjCtTpyQ1wd9',
    menus: [
      {
        dispensaryType: 'medical',
        id: '5796'
      },
      {
        dispensaryType: 'recreational',
        id: '5797'
      },
    ],
    hours: [
      {
        dispensaryTypes: ['medical', 'recreational'],
        daily: [
          {
            day: 'Monday',
            description: '10am-7pm'
          },
          {
            day: 'Tuesday',
            description: '10am-7pm'
          },
          {
            day: 'Wednesday',
            description: '10am-7pm'
          },
          {
            day: 'Thursday',
            description: '10am-7pm'
          },          {
            day: 'Friday',
            description: '10am-7pm'
          },
          {
            day: 'Saturday',
            description: '10am-7pm'
          },
          {
            day: 'Sunday',
            description: '10am-7pm'
          },
        ]
      }
    ],
  },
  {
    id: 'md001',
    dispensaryTypes: ['medical', 'recreational'],
    name: "Olney, MD",
    latlng: {
      lat: 39.152880,
      lng: -77.067560
    },
    address: {
      street: "18070 Georgia Ave",
      city: "Olney",
      state: "MD",
      zip: "20832",
      country: "United States"
    },
    phone: '301.774.0840',
    email: 'olney@sweetspotfarms.com',
    googleLink: 'https://goo.gl/maps/z9ouy3SPuYksbzv46',
    menus: [
      {
        dispensaryType: 'medical',
        id: '857'
      },
      {
        dispensaryType: 'recreational',
        id: '5440'
      },
    ],
    hours: [
      {
        dispensaryTypes: ['medical', 'recreational'],
        daily: [
          {
            day: 'Monday',
            description: '10am-8pm'
          },
          {
            day: 'Tuesday',
            description: '10am-8pm'
          },
          {
            day: 'Wednesday',
            description: '10am-8pm'
          },
          {
            day: 'Thursday',
            description: '10am-8pm'
          },          {
            day: 'Friday',
            description: '10am-8pm'
          },
          {
            day: 'Saturday',
            description: '10am-8pm'
          },
          {
            day: 'Sunday',
            description: '10am-8pm'
          },
        ]
      }
    ],
  },
  {
    id: 'nj001',
    dispensaryTypes: ['medical', 'recreational'],
    name: "Voorhees, NJ",
    latlng: {
      lat: 39.847940,
      lng: -74.988830
    },
    address: {
      street: "903 White Horse Rd",
      city: "Voorhees",
      state: "NJ",
      zip: "08043",
      country: "United States"
    },
    phone: '856.882.3481',
    email: 'voorhees@sweetspotfarms.com',
    googleLink: 'https://goo.gl/maps/LrDKfCArNMnL85YV9',
    menus: [
      {
        dispensaryType: 'medical',
        id: '4855'
      },
      {
        dispensaryType: 'recreational',
        id: '5377'
      },
    ],
    hours: [
      {
        dispensaryTypes: ['medical', 'recreational'],
        daily: [
          {
            day: 'Monday',
            description: '10am-8pm'
          },
          {
            day: 'Tuesday',
            description: '10am-8pm'
          },
          {
            day: 'Wednesday',
            description: '10am-8pm'
          },
          {
            day: 'Thursday',
            description: '10am-8pm'
          },          
          {
            day: 'Friday',
            description: '10am-8pm'
          },
          {
            day: 'Saturday',
            description: '10am-8pm'
          },
          {
            day: 'Sunday',
            description: '10am-8pm'
          },
        ]
      },
      {
        dispensaryTypes: ['medical'],
        daily: [
          {
            day: 'Monday',
            description: '8am-10am'
          },
          {
            day: 'Tuesday',
            description: '8am-10am'
          },
          {
            day: 'Wednesday',
            description: '8am-10am'
          },
          {
            day: 'Thursday',
            description: '8am-10am'
          },          {
            day: 'Friday',
            description: '8am-10am'
          },
          {
            day: 'Saturday',
            description: '8am-10am'
          },
          {
            day: 'Sunday',
            description: '8am-10am'
          },
        ]
      }
    ],
  },
  {
    id: 'ri001',
    dispensaryTypes: ['medical', 'recreational'],
    name: "Exeter, RI",
    latlng: {
      lat: 41.527790,
      lng: -71.548910
    },
    address: {
      street: "560 S. County Trail",
      city: "Exeter",
      state: "RI",
      zip: "02822",
      country: "United States"
    },
    phone: '401.268.5126',
    email: 'exeter@sweetspotfarms.com',
    googleLink: 'https://goo.gl/maps/zHwVABqmt3Vi8Jjn9',
    menus: [
      {
        dispensaryType: 'medical',
        id: '4545'
      },
      {
        dispensaryType: 'recreational',
        id: '4996'
      },
    ],
    hours: [
      {
        dispensaryTypes: ['medical', 'recreational'],
        daily: [
          {
            day: 'Monday',
            description: '9am-8pm'
          },
          {
            day: 'Tuesday',
            description: '9am-8pm'
          },
          {
            day: 'Wednesday',
            description: '9am-8pm'
          },
          {
            day: 'Thursday',
            description: '9am-8pm'
          },          {
            day: 'Friday',
            description: '9am-8pm'
          },
          {
            day: 'Saturday',
            description: '9am-8pm'
          },
          {
            day: 'Sunday',
            description: '9am-8pm'
          },
        ]
      }
    ],
  },
  {
    id: 'vt001',
    dispensaryTypes: ['recreational'],
    name: "Essex Junction, VT",
    latlng: {
      lat: 44.497150,
      lng: -73.125140
    },
    address: {
      street: "139 Pearl St",
      city: "Essex Junction",
      state: "VT",
      zip: "05452",
      country: "United States"
    },
    phone: '802.871.5895',
    email: 'essexjunction@sweetspotfarms.com',
    googleLink: 'https://maps.app.goo.gl/KizGVjGedVfMS5qU9',
    menus: [
      {
        dispensaryType: 'recreational',
        id: '6145'
      }
    ],
    hours: [
      {
        dispensaryTypes: ['recreational'],
        daily: [
          {
            day: 'Monday',
            description: '10am-7pm'
          },
          {
            day: 'Tuesday',
            description: '10am-7pm'
          },
          {
            day: 'Wednesday',
            description: '10am-7pm'
          },
          {
            day: 'Thursday',
            description: '10am-7pm'
          },          {
            day: 'Friday',
            description: '10am-7pm'
          },
          {
            day: 'Saturday',
            description: '10am-7pm'
          },
          {
            day: 'Sunday',
            description: '10am-7pm'
          },
        ]
      }
    ],
  },
  {
    id: 'ct002',
    dispensaryTypes: ['medical', 'recreational'],
    name: "Stamford, CT",
    latlng: {
      lat: 41.508730,
      lng: -73.052840
    },
    address: {
      street: "111 High Ridge Rd",
      city: "Stamford",
      state: "CT",
      zip: "06905",
      country: "United States"
    },
    phone: '860.321.1291',
    email: 'stamford@sweetspotfarms.com',
    googleLink: 'https://g.co/kgs/yLbrBnx',
    menus: [
      {
        dispensaryType: 'recreational',
        id: '6159'
      },
      {
        dispensaryType: 'medical',
        id: '6158'
      }
    ],
    hours: [
      {
        dispensaryTypes: ['recreational', 'medical'],
        daily: [
          {
            day: 'Monday',
            description: '10am-7pm'
          },
          {
            day: 'Tuesday',
            description: '10am-7pm'
          },
          {
            day: 'Wednesday',
            description: '10am-7pm'
          },
          {
            day: 'Thursday',
            description: '10am-7pm'
          },          {
            day: 'Friday',
            description: '10am-7pm'
          },
          {
            day: 'Saturday',
            description: '10am-7pm'
          },
          {
            day: 'Sunday',
            description: '11am-5pm'
          },
        ]
      }
    ],
  },

];

export const productData = [
  {
    id: 'flowers',
    name: 'Flowers',
    images: [
      {
        fileName: 'product-flower.png',
        altText: 'Green jar of Sweetspot Watermellon Stardust on white background',
      }
    ],
    content1: 'Our premium flower is grown with the highest standards possible.',
    bulletPoints1: [
      'consistent quality and effect',
      '100% pesticide free',
      'hand-packed processing',
      'grown in highly sustainable facilities',
      'eco-friendly paper packaging'
    ],
  },
  {
    id: 'preRolls',
    name: 'Pre-Rolls',
    images: [
      {
        fileName: 'product-preroll.png',
        altText: 'A Sweetspot pre-roll next to a green tin of pre-rolls on a white background'
      }
    ],
    content1: 'The same quality Sweetspot flowers, pre-rolled for your convenience.',
    bulletPoints1: [
      'rolled with fresh flowers only, never trim',
      '100% pesticide free',
      'organic hemp, unbleached paper',
      'fully recyclable tin and plant-based inks printing'
    ]
  },
  {
    id: 'vapeCart',
    name: 'Vape Cart',
    images: [{
      fileName: 'product-vape-cart.png',
      altText: 'A Sweetspot premium vape cart on a white background'
    }],
    content1: 'High-quality vape carts from Sweetspot flowers.',
    bulletPoints1: [
      'full-spectrum oil',
      '100% pesticide free',
      'grown in highly sustainable facilities',
      'user friendly packaging made from recycled paper',
      'compatible with 510 battery'
    ]
  },
  {
    id: 'gummies',
    name: 'Gummies',
    variants: [
      {
        id: 'spots',
        name: 'Spots',
        images: [
          {
            fileName: 'product-spots.png',
            altText: 'Four tins of Sweetspot Spot Gummies on a white background'
          }
        ],
        content1: 'Delicious, all-purpose gummies.',
        bulletPoints1: [
          'Sweetspot full-spectrum oil',
          '5mg THC per piece',
          '100% pesticide free',
          '100% vegan',
          'melt-resistant',
          'recyclable packaging'
        ]
      },
      {
        id: 'dreamies',
        name: 'Dreamies',
        images: [
          {
            fileName: 'product-dreamies.png',
            altText: 'Three tins of Sweetspot Dreamies Gummies on a white background'
          }
        ],
        content1: 'Night-time gummies for sweet dreams.',
        bulletPoints1: [
          '5mg THC / 5mg CBN per piece',
          'infused with chamomile',
          '100% pesticide free',
          '100% vegan',
          'melt-resistant',
          'recyclable packaging'
        ]
      }
    ]
  },
  {
    id: 'mist',
    name: 'Mist',
    comingSoon: true,
  },
];

export const franchiseSteps = [
  'Call with Franchise Director.',
  'Complete franchise candidate application.',
  'Review Franchise Disclosure Document.',
  'Begin mutual discovery process.',
  'Attend Discovery Day.',
  'Sweetspot approves franchise candidate.',
  'Complete background check and credit check.',
  'Execute franchise agreement.',
];

export const franchiseFAQ = [
  {
    id: 'general',
    question: 'How does franchising work in the cannabis industry?',
    answer: 'Franchising in the cannabis industry is just like franchising in any other industry – fitness, food, etc. Sweetspot Franchising LLC has registered their franchise offering/FDD with various states that have legalized cannabis.',
  },
  {
    id: 'why',
    question: 'Why should I choose working with Sweetspot vs. doing this on my own?',
    answer: 'The cannabis industry is complex and ever changing. Without the proper know-how, opening and operating an independent dispensary can be difficult and oftentimes extremely costly. With years of hands-on experience operating successful businesses across 5 states, Sweetspot has developed a blueprint for achieving success within the cannabis industry. Franchising a Sweetspot will allow you to avoid costly mistakes, gain access to valuable resources, get to market faster, and become part of a well loved and recognized brand with a growing footprint.',
  },
  {
    id: 'source',
    question: 'How do you source the cannabis products for resale?',
    answer: 'Due to federal regulations, cannabis products cannot be shipped across state lines. Cannabis products are sourced from local cultivators within the state that dispensaries operate in. Sweetspot will use existing cultivator relationships to aid in sourcing products and achieving fair wholesale pricing.',
  },
  {
    id: 'process',
    question: 'What does the process of becoming a franchisee look like?',
    answer: 'Candidates begin a mutual discovery process which includes a series of conversations and meetings that ensure we are a good fit for one another. Our Franchise Director is your guide in answering any questions about the business, franchisor, franchisee requirements and FDD. We would schedule a site visit at one of our existing dispensaries for a Discovery Day where you will have the opportunity to see operations in person as well as meet with the operations teams. If qualifications are met based on your business acumen and financial strength, a background check and credit check will be submitted. Upon the receipt of a successful result, a franchise agreement will be executed.',
  },
  {
    id: 'cost',
    question: 'What does it cost to become a franchisee?',
    answer: 'Just like any franchise model, you can anticipate a franchisee fee upon signing of the franchise agreement. In addition to a Product Sourcing Fee – 2% of gross sales (capped at $249,999 per calendar year), you will also be responsible for build-out costs, product supplies, security systems, walk-in vaults, computer systems, and other operating expenses. These fees are detailed in the FDD.',
  },
  {
    id: 'support',
    question: 'What kind of support and ongoing support do you provide?',
    answer: 'Aside from all of the pre-opening support to ensure you successfully open your doors, our franchise operations team will continue to work with you after opening to ensure you are achieving all growth milestones through continued frequent communication, in location visits and other support as required. Being a part of a network or system also allows for on-going collaboration and camaraderie which you don’t experience being one your own.',
  },
]

export const rhodeIslandFAQ = [
  {
    question: 'What is the minimum order?',
    answer: 'The minimum order for delivery is $75.'
  },
  {
    question: 'How much is the delivery fee?',
    answer: 'Delivery fees are waived for medical patients.'
  },
  {
    question: 'Can I receive deliveries outside of Rhode Island?',
    answer: `No, sorry!  We can only deliver to Rhode Island patients with Rhode Island addresses that match their ID's.`
  },
  {
    question: 'How does payment work?',
    answer: 'At this time, we can only accept payment via CanPay. Payment will be taken in person at the time of delivery.'
  },
  {
    question: 'When will I receive my order?',
    answer: `The following day.  We'll contact you in the morning with a delivery window along with a call 15 minutes before arrival.`
  },
  {
    question: 'Can I receive deliveries if I have an authorized purchaser or caregiver?',
    answer: 'No.'
  },
];

export const devMode = window.location.href.includes('localhost');